import React, { useState, useEffect, useCallback } from 'react';
import ServiceCard from './ServiceCard';
import InfoBox from './InfoBox';
import '../css/services.css';

// Konstanten in ein Config-Objekt auslagern
const GRID_CONFIG = {
  cardWidth: 413,
  gapWidth: 30,
  headerHeight: 54,
  scrollOffset: 50
};

const CardGrid = () => {
  const [cards, setCards] = useState([]);
  const [infoBoxState, setInfoBoxState] = useState({
    visible: false,
    lastClickedIndex: null,
    position: null
  });

  // Fetch der Kartendaten
  useEffect(() => {
    const fetchCards = async () => {
      try {
        const response = await fetch('/json/services.json');
        const data = await response.json();
        setCards(data);
      } catch (error) {
        console.error('Error loading cards:', error);
      }
    };
    fetchCards();
  }, []);

  // Berechnung der Karten pro Reihe
  const calculateCardsPerRow = useCallback((containerWidth) => {
    const { cardWidth, gapWidth } = GRID_CONFIG;
    return Math.max(1, Math.floor((containerWidth + gapWidth) / (cardWidth + gapWidth)));
  }, []);

  // Berechnung der InfoBox Position
  const calculateInfoBoxPosition = useCallback((clickedIndex, containerWidth) => {
    const cardsPerRow = calculateCardsPerRow(containerWidth);
    const rowIndex = Math.floor(clickedIndex / cardsPerRow);
    const lastCardInRowIndex = (rowIndex + 1) * cardsPerRow - 1;
    return Math.min(lastCardInRowIndex, cards.length - 1);
  }, [cards.length, calculateCardsPerRow]);

  // Update der InfoBox Inhalte
  const updateInfoBoxContent = useCallback((card) => {
    const filename = card.title.replace(/ /g, "-").toLowerCase();
    
    // Icon und Titel aktualisieren
    const iconContainer = document.getElementById('iconContainer');
    if (iconContainer) {
      iconContainer.innerHTML = `
        <img src="./ressources/service-icons-3/${filename}.svg?${performance.now()}" 
             alt="${card.title} Icon">
        <h3>${card.title}</h3>
      `;
    }

    // Text-Inhalt aktualisieren
    const textContainer = document.getElementById('textContainer');
    if (textContainer) {
      textContainer.innerHTML = `
        <p>${card.description}</p>
        <h3>Unsere Leistungen</h3>
        <p>${card.leistungen}</p>
      `;
    }
  }, []);

  // Scroll zur InfoBox
  const scrollToInfoBox = useCallback(() => {
    const infoBox = document.getElementById('infoBox');
    if (infoBox) {
      const infoBoxTop = infoBox.getBoundingClientRect().top + 
                        window.scrollY - 
                        GRID_CONFIG.headerHeight - 
                        GRID_CONFIG.scrollOffset;
      window.scrollTo({ top: infoBoxTop, behavior: 'smooth' });
    }
  }, []);

  // Handler für Kartenklicks
  const handleCardClick = useCallback((index) => {
    const cardGrid = document.getElementById('card-grid');
    const newPosition = calculateInfoBoxPosition(index, cardGrid.offsetWidth);
    
    setInfoBoxState(prev => ({
      visible: prev.lastClickedIndex !== index || !prev.visible,
      lastClickedIndex: index,
      position: newPosition
    }));
  }, [calculateInfoBoxPosition]);

  // Resize Handler
  useEffect(() => {
    const handleResize = () => {
      const { visible, lastClickedIndex } = infoBoxState;
      if (!visible || lastClickedIndex === null) return;

      const infoBox = document.getElementById('infoBox');
      const cardGrid = document.getElementById('card-grid');
      if (!infoBox || !cardGrid) return;

      const newPosition = calculateInfoBoxPosition(lastClickedIndex, cardGrid.offsetWidth);
      
      // Neu positionieren
      infoBox.remove();
      const targetCard = cardGrid.children[newPosition];
      if (targetCard?.nextSibling) {
        cardGrid.insertBefore(infoBox, targetCard.nextSibling);
      } else {
        cardGrid.appendChild(infoBox);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [infoBoxState, calculateInfoBoxPosition]);

  // Effect für InfoBox Updates
  useEffect(() => {
    const { visible, lastClickedIndex } = infoBoxState;
    if (visible && lastClickedIndex !== null) {
      updateInfoBoxContent(cards[lastClickedIndex]);
      scrollToInfoBox();
    }
  }, [infoBoxState, cards, updateInfoBoxContent, scrollToInfoBox]);

  return (
    <div id="card-grid" className="cardGrid">
      {cards.map((card, index) => (
        <React.Fragment key={index}>
          <ServiceCard
            {...card}
            onClick={() => handleCardClick(index)}
          />
          {index === infoBoxState.position && infoBoxState.visible && (
            <InfoBox isVisible={infoBoxState.visible} />
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default CardGrid;